
import { defineComponent } from "vue";
export default {
  props: {
    typeArr: Array as () => string[],
    filteredArr: Array as () => string[],
    handleCheckbox: Function,
    name: String,
  },
};
