
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    options: Array as () => string[],
    name: String,
    sortType: String,
    label: String,
  },
});
