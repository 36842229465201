
import { TimingResult } from "../types/index";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    processedTimingResults: Array as () => TimingResult[],
    tableColumnNames: Array as () => string[],
  },
});
